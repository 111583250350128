/* вручну */
header {
    border-bottom: 3px solid #50b1f9;
    position: fixed;
    top: 0px;
    z-index: 10;
    min-width: 100vw;
    background-color: #fff;
}
main {
    padding-top: 99px;
    /* min-height: 100vh; */
}
.menu-nav .row {
    flex-wrap: nowrap;
}
.logo {
    width: fit-content;
}
.logo a {
    padding-right: 0px;
    display: flex;
    width: 87px;
    height: auto;
    justify-content: center;
}

@media (max-width: 992px) {
    .menu-area {
      padding: 0px;
    }
}
