/* 1. Theme default css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600&amp;display=swap');
body {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 14px;
	color: #7b7b7b;
	font-style: normal;
}
.img,
img {
	max-width: 100%;
	transition: all 0.3s ease-out 0s;
}
.f-left {
	float: left
}
.f-right {
	float: right
}
.fix {
	overflow: hidden
}
a,
.button {
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
a:focus,
.btn:focus,
.button:focus {
	text-decoration: none;
	outline: none;
	box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
	color: #50b1f9;
	text-decoration: none;
}
a,
button {
	color: #50b1f9;
	outline: medium none;
}
button:focus,input:focus,input:focus,textarea,textarea:focus{outline: 0}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Poppins', sans-serif;
	color: #282828;
	margin-top: 0px;
	font-style: normal;
	font-weight: 600;
	text-transform: normal;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}
h1 {
	font-size: 40px;
	font-weight: 700;
}
h2 {
	font-size: 35px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
ul {
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none
}
p {
	font-size: 14px;
	font-weight: 500;
	line-height: 26px;
	color: #7b7b7b;
	margin-bottom: 15px;
}
hr {
	border-bottom: 1px solid #eceff8;
	border-top: 0 none;
	margin: 30px 0;
	padding: 0;
}
label {
	color: #7e7e7e;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}
*::-moz-selection {
	background: #50b1f9;
	color: #fff;
	text-shadow: none;
}
::-moz-selection {
	background: #50b1f9;
	color: #fff;
	text-shadow: none;
}
::selection {
	background: #50b1f9;
	color: #fff;
	text-shadow: none;
}
*::-moz-placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
.theme-overlay {
	position: relative
}
.theme-overlay::before {
	background: #50b1f9 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.6;
	position: absolute;
	top: 0;
	width: 100%;
}
.separator {
	border-top: 1px solid #f2f2f2
}
.slick-slide:focus,
.slick-slide:focus-visible {
	outline: none;
}
/* button style */
.btn {
    background: #50b1f9 none repeat scroll 0 0;
    border: none;
    border-radius: 0px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.4;
    margin-bottom: 0;
    padding: 18px 34px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all ease 700ms;
    -moz-transition: all ease 700ms;
    -webkit-transition: all ease 700ms;
    -ms-transition: all ease 700ms;
    -o-transition: all ease 700ms;
    vertical-align: middle;
    white-space: nowrap;
	box-shadow: 0px 9px 16.74px 1.26px rgba(80, 177, 249, 0.21);
	position: relative;
	overflow: hidden;
	z-index: 1;
}
.btn:hover {
	background: #111e49;
    color: #fff;
    box-shadow: none;
}
.breadcrumb > .active {
	color: #888;
}

/* scrollUp */
.scroll-top {
	width: 50px;
	height: 50px;
	line-height: 50px;
	position: fixed;
	bottom: 105%;
	right: 50px;
	font-size: 16px;
	border-radius: 0;
	z-index: 99;
	color: #fff;
	text-align: center;
	cursor: pointer;
	background: #50b1f9;
	transition: 1s ease;
	border: none;
    opacity: 0;
}
.scroll-top.open {
    opacity: 1;
	bottom: 30px;
}
.scroll-top::after {
	position: absolute;
	z-index: -1;
	content: '';
	top: 100%;
	left: 5%;
	height: 10px;
	width: 90%;
	opacity: 1;
	background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
.scroll-top:hover {
	background: #111e49;
}
.container-inner {
	max-width: 1270px;
}

/* 20. Core-features */
.core-features-area {
    background: #ebf5fb;
    padding: 70px 0 40px;
}
.core-features-item {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 10px 0px rgba(80, 177, 249, 0.11);
    padding: 35px 32px;
	transform: translateY(0);
	transition: .3s linear;
}
.core-features-item:hover {
	transform: translateY(-7px);
}
.core-features-icon {
    width: 76px;
    height: 76px;
    flex: 0 0 76px;
    border: 1px dashed #e6e6e6;
    border-radius: 50%;
    margin-right: 23px;
	background: #ebf5fb;
	display: flex;
	align-items: center;
	justify-content: center;
}
.core-features-icon img {
    max-width: 35px;
}
.core-features-content .title {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 8px;
}
.core-features-content p {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 24px;
}
.server-button {
	position: fixed;
	z-index: 1000;
}


